<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <el-form-item label="用户名：" label-width="80px" style="width: 330px;">
              <el-input placeholder="请输入用户名" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
            </el-form-item>
            <el-form-item label="请选择部门：" prop="department_id" label-width="100px" style="width: 350px;">
              <Bepartment style="width: 200px;" ref="Bepartment" v-model="formLabelAlign.department_id" />
            </el-form-item>
            <el-form-item label="用户类型：" label-width="90px">
              <el-select v-model="formLabelAlign.user_type" placeholder="请选择">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个用户</span>
            <span class="span2">已选择 {{ more_List.length }} 个用户</span>
            <i @click="way_tableData" class="span_i el-icon-refresh">数据更新</i>
          </p>
          <div>
            <el-button type="primary" @click="on_pop_addplus()" icon="el-icon-plus">新建</el-button>
            <el-button @click="leadg_show = true">导入</el-button>

            <el-button @click="on_deleteList" icon="el-icon-close">删除</el-button>
            <el-button @click="on_export">导出</el-button>
            <el-button @click="on_fromding">同步钉钉</el-button>
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="id" label="ID"> </el-table-column>
            <el-table-column prop="nickname" label="名称"></el-table-column>
            <el-table-column prop="department_name" label="所属部门"></el-table-column>
            <el-table-column prop="user_type_tips" label="用户类型"></el-table-column>
            <el-table-column prop="gender_text" label="性别"></el-table-column>
            <!-- <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  @change="on_is_enable($event, scope.row.id)"
                  v-model="scope.row.is_enable"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </template>
            </el-table-column> -->

            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary" @click="on_pop_addplus(scope.row.id)">修改</el-link>
                <!-- <el-link class="list_sty" type="primary" @click="on_journal()"
                  >日志</el-link
                > -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
      <!-- 新建修改弹窗 -->
      <el-dialog :width="dialogWidth" :title="title" show-close :visible.sync="dialog_show" center>
        <UserRedact v-if="dialog_show" ref="UserRedact" :refbool.sync="dialog_show" @way_tableData="way_tableData" />
      </el-dialog>

      <!-- 导入弹窗 -->
      <el-dialog title="导入" show-close :visible.sync="leadg_show" center width="10%">
        <leadxlsx type="user" :leadg_show.sync="leadg_show" url="/api/user/import" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import Bepartment from "../../components/Bepartment";
import UserRedact from "../../components/view_redact/user_redact.vue";
import leadxlsx from "../../components/lead_xlsx";

import {
  useruserlist,
  userdelete,
  userStatusUpdate,
  getDingDepartmentUser
} from "../../assets/request/api";
export default {
  name: "risk_source",
  mixins: [table_minin],
  components: { Bepartment, UserRedact, leadxlsx },
  data() {
    return {
      leadg_show: false,
      dialogWidth: "800px",
      title: "",
      dialog_show: false,
      formLabelAlign: {
        name: "",
        department_id: "",
        user_type: "",
      },
    };
  },
  created() {
    this.url = useruserlist;
    this.delete_Url = userdelete;
    this.is_enableUrl = userStatusUpdate;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "type_list";
    this.way_tableData();
  },
  methods: {
    on_pop_addplus(id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.UserRedact.way_show(id);
      });
    },
    on_fromding() {
      getDingDepartmentUser().then((res) => {
        console.log(res);

      });
    }
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.el-dialog {
  min-width: 500px;
}
</style>
