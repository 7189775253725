<template>
  <div class="from_outer">
    <div>
      <el-form
        ref="formName"
        label-width="120px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="用户名："
              prop="username"
              :rules="[{ required: true, message: '请输入用户名' }]"
            >
              <el-input
                placeholder="请输入用户名"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.username"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名密码：">
              <el-input
                show-password
                placeholder="请输入用户名密码"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.login_pwd"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="角色组ID："
              prop="role_id"
              :rules="[{ required: true, message: '请选择角色组ID' }]"
            >
              <el-select
                class="form-inp"
                v-model="formLabelAlign.role_id"
                placeholder="角色组ID："
              >
                <el-option
                  v-for="item in role_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="部门："
              prop="department_id"
              :rules="[{ required: true, message: '请选择部门' }]"
            >
              <Bepartment
                ref="Bepartment"
                class="form-inp"
                v-model="formLabelAlign.department_id"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="用户姓名："
              prop="nickname"
              :rules="[{ required: true, message: '请输入用户姓名' }]"
            >
              <el-input
                placeholder="请输入用户姓名"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.nickname"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户手机号：" prop="mobile">
              <el-input
                placeholder="请输入用户手机号"
                size="mini"
                class="form-inp"
                v-model.number="formLabelAlign.mobile"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="性别："
              prop="gender"
              :rules="[{ required: true, message: '请选择性别' }]"
            >
              <el-select
                class="form-inp"
                v-model="formLabelAlign.gender"
                placeholder="请选择性别："
              >
                <el-option
                  v-for="item in gender_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="用户类型："
              prop="user_type"
              :rules="[{ required: true, message: '请选择用户类型' }]"
            >
              <el-select
                class="form-inp"
                v-model="formLabelAlign.user_type"
                placeholder="请选择用户类型"
              >
                <el-option
                  v-for="item in user_typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="状态："
              prop="status"
              :rules="[{ required: true, message: '请选择状态' }]"
            >
              <el-select
                class="form-inp"
                v-model="formLabelAlign.status"
                placeholder="请选择状态："
              >
                <el-option
                  v-for="item in status_list"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="安全岗职务："
              prop="office_position"
              :rules="[{ required: true, message: '请选择安全岗职务' }]"
            >
              <el-select
                class="form-inp"
                v-model="formLabelAlign.office_position"
                placeholder="请选择安全岗职务："
              >
                <el-option
                  v-for="item in office_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { usersaveInfo, usersave } from "../../assets/request/api";
import Bepartment from "../../components/Bepartment";
export default {
  name: "user_redact",
  components: {
    Bepartment,
  },
  data() {
    let phoneRuel = (rule, value, callback) => {
      if (!this.$tools.verify_phone(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };

    return {
      type_name: "保存",
      //要修改的id
      id: "",
      status_list: [],
      gender_list: [],
      role_list: [],
      office_list: [],
      formLabelAlign: {
        username: "",
        department_id: "",
        login_pwd: "",
        role_id: "",
        nickname: "",
        mobile: "",
        gender: "",
        status: "",
        user_type: "",
        office_position: "",
      },
      user_typeList: [],
      rules: {
        mobile: [
          { validator: phoneRuel, trigger: "blur" },
          { required: true, message: "请输入用户手机号" },
        ],
      },
    };
  },
  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      usersaveInfo(froms).then((res) => {
        let {
          info,
          status_list,
          gender_list,
          role_list,
          office_list,
          type_list,
        } = res.data;
        this.status_list = status_list;
        this.gender_list = gender_list;
        this.office_list = office_list;
        this.user_typeList = type_list;
        this.role_list = role_list;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let {
            username,
            department_id,
            login_pwd,
            role_id,
            nickname,
            mobile,
            gender,
            status,
            user_type,
            office_position,
          } = info;
          this.$refs.Bepartment.bepartment_List = department_id;
          formLabelAlign.id = id;
          formLabelAlign.username = username;
          formLabelAlign.user_type = user_type;
          formLabelAlign.department_id = department_id;
          formLabelAlign.login_pwd = login_pwd;
          formLabelAlign.role_id = role_id;
          formLabelAlign.nickname = nickname;
          formLabelAlign.mobile = mobile;
          formLabelAlign.gender = gender;
          formLabelAlign.status = status;
          formLabelAlign.office_position = office_position;
        }
      });
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));

          usersave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>