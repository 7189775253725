<template>
  <div>
    <el-tag v-if="upload_File" closable @close="on_close">
      {{ upload_File.name }}
    </el-tag>

    <el-upload
      v-else
      class="upload-demo"
      ref="upload"
      action="/api/duty/Import"
      :headers="this.$tools.config()"
      :data="{}"
      :limit="1"
      :show-file-list="false"
      :on-change="fileaveToUrl"
      :auto-upload="false"
      list-type="picture"
    >
      <el-button slot="trigger" size="small" type="primary">选择文件</el-button>
    </el-upload>
    <el-link type="primary" @click="on_download">模板下载</el-link>
    <div style="text-align: center; margin: 20px 0">
      <el-button @click="on_lead" type="primary">确定导入</el-button>
      <el-button @click="on_cancel()">取消</el-button>
    </div>
  </div>
</template>

<script>
import { formcustom } from "../assets/request/api";
export default {
  props: ["leadg_show", "url", "type"],
  data() {
    return {
      //上传的文件File
      upload_File: "",
    };
  },

  methods: {
    fileaveToUrl(file) {
      let { raw } = file;
      const isLt2M = raw.size / 1024 / 1024 < 20;
      const isJPG =
        raw.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        raw.type === "application/vnd.ms-excel";

      if (!isJPG) {
        this.$message.error("上传文件只能是xlsx!");
        this.$refs.upload.uploadFiles = [];
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 20MB!");
        this.$refs.upload.uploadFiles = [];
        return;
      }
      this.upload_File = raw;
      this.$refs.upload.uploadFiles = [];
    },
    //导入上传
    on_lead() {
      let { upload_File, url } = this;
      if (!upload_File) {
        this.$message({
          message: "请上传在进行导入",
          type: "warning",
        });
        return;
      }
      let formData = new FormData();
      formData.append("file", upload_File);
      formcustom(url, formData).then((res) => {
        if (res.code == 200 || res.code == 0) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    on_cancel() {
      this.$emit("update:leadg_show", false);
    },
    on_close() {
      this.upload_File = "";
    },
    on_download() {
      var thatHttp = window.location.protocol;
      console.log(thatHttp);
      console.log(window.location.host);

      let { type } = this;
      let type_url = "";
      switch (type) {
        case "personnel":
          type_url = "file/重点人群导入模板.xlsx";
          break;
        case "dangerous":
          type_url = "file/风险点导入模板.xlsx";
          break;
        case "duty":
          type_url = "file/排班模板.xlsx";
          break;
        case "user":
          type_url = "file/用户导入模板.xlsx";
          break;
      }

      // let src = `${thatHttp}${window.location.host}/${type_url}`;
      let src = `/${type_url}`;
      console.log(src)
      window.open(src);
    },
  },
};
</script>

<style lang="less" scoped>
.upload-demo {
  // text-align: center;
}
</style>